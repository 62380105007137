.radial-button {
  width: 100px;
  height: 100px;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  transition: background 0.2s;
  outline: none;
}

.radial-button:hover {
  filter: brightness(1.2);
}

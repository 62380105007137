@tailwind base;
@tailwind components;
@tailwind utilities;

/* #00df9a  #000300*/

/* body{
    background-color: #03001c;
  } */

  /* body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
 } */
  
  input.input-error, 
    select.input-error {
      border-color : #fc8181;
    }
  

  button:disabled {
   opacity: 0.35;
  }
 

 .react-confirm-dialog-bg { /*Modal Background*/
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background: rgba(0,0,0,0.35);
  user-select: none;
  z-index: 1000; 
}

.react-confirm-dialog-content {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  margin: auto;
  height: 200px;
  width: 280px;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  justify-content: spacebetween;
  font-size: 20px;
}
 
button.cancel {
  color:red;
  margin-left: 15px;  
  margin-top: 15px;  
  background-color: #f2d3d3;
  padding: 8px;
  border-radius: 5px;
}

button.confirm {
  color:green;
  margin-top: 15px;  
  padding: 8px;
  background-color: #d3f2e5;
  border-radius: 5px;
} 

.led-box {
  height: 30px;
  width: 25%;
  text-align: center;
  margin: 10px 0;  
}
 

.led-red {
  margin: 0 auto;
  width: 18px;
  height: 18px;
  text-align: center;
  background-color: #F00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
 
}

.led-yellow {
  margin: 0 auto;
  width: 18px;
  height: 18px;
  background-color: #FF0;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #FF0 0 2px 12px;
 /* -webkit-animation: blinkYellow 1s infinite;
  -moz-animation: blinkYellow 1s infinite;
  -ms-animation: blinkYellow 1s infinite;
  -o-animation: blinkYellow 1s infinite;
  animation: blinkYellow 1s infinite;*/
}


.led-green {
  margin: 0 auto;
  width: 18px;
  height: 18px;
  background-color: #00ff66;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;
}

.led-blue {
  margin: 0 auto;
  width: 18px;
  height: 18px;
  background-color: #24E0FF;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px, #3F8CFF 0 2px 14px;
}





 

  .font-loader {
    font-family: 'Roboto';
  }
  
  :root { 
    --color-dark-font: #0969DA;
    --color-light-font:  #34495E;

    --color-dark-fontSpecial: #F5F5F5;
    --color-light-fontSpecial:  #000300;

    --color-dark-fontSecondLevel: #607D8B;
    --color-light-fontSecondLevel: #B0BEC5;

    --color-dark-fontButton: #F5F5F5;
    --color-light-fontButton: #03001c;

    --color-dark-fontButtonSpecial: #E8EAF6;
    --color-light-fontButtonSpecial: #000300;

    --color-dark-buttonBackground: #2C3E50;
    --color-light-buttonBackground: #607D8B;

    --color-dark-buttonBackgroundSpecial: #1A237E;
    --color-light-buttonBackgroundSpecial: #2C3E50;

    --color-dark-backgroundMain: #03001c;
    --color-light-backgroundMain: #DCDCDC;

    --color-dark-bakcgroundSecond: #BDC3C7; 
    --color-light-backgroundSecond:  #F5F5F5;

    --color-dark-bakcgroundCard: #ECEFF1;
    --color-light-backgroundCard: #2d3748;

    --color-light-menu-backgroundSearch: #93C5FD; 
    --color-dark-menu-backgroundSearch:  #F5F5F5;

    /* -----table ----------- */
    --color-dark-tblTxtHeader: #F5F5F5;
    --color-light-tblTxtHeader: #000300;

    --color-gray-tbl200: #E5E7EB;
    --color-gray-tbl700: #334155;
    --color-gray-tbl100: #F5F5F5;
    --color-gray-tbl0: #FFFFFF;
    --color-gray-tbl300: #CBD5E1; 
    --color-gray-tbl500: #64748B; 
    --color-gray-tbl600: #475569; 
    --color-gray-tbl400: #94A3B8; 
    --color-gray-tbl50: #F8FAFC; 
    --color-gray-tbl800: #1E293B; 
    --color-gray-tbl900: #18181B; 
    /* ------ end table design------ */
  }
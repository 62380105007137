.back-button-container {
  position: relative;
  width: 40px;
  height: 40px;
}

.back-button {
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.back-button-circle {
  position: absolute;
  top: -4px;
  left: -4px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: border-color 0.3s ease-in-out;
}

.back-button:hover .back-button-circle {
  border-color: black;
}

.back-icon {
  position: relative;
  z-index: 1;
  color: black;
}

 

.table-container {
  width: 100%;
}

.layout {
  display: flex;
  min-height: 100vh;
}

.menu {
  flex: 0 0 auto;
  width: 72px; /* Adjust as needed */
  transition: width 0.3s;
  overflow: hidden;
}

.menu.expanded {
  width: 72px;
}

.menu.collapsed {
  width: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.header {
  /* Your header styles */
}

.main-content {
  flex-grow: 1;
  overflow: auto;
}

.footer {
  /* Your footer styles */
}


* {
  font-family: Roboto, sans-serif;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-BoldItalic.ttf") format("truetype");
  font-weight: bold-italic;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Black.ttf") format("truetype");
  font-weight: black;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-BlackItalic.ttf") format("truetype");
  font-weight: black-italic;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Medium.ttf") format("truetype");
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-MediumItalic.ttf") format("truetype");
  font-weight: medium-italic;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Italic.ttf") format("truetype");
  font-weight: italic;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Light.ttf") format("truetype");
  font-weight: ligth;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Thin.ttf") format("truetype");
  font-weight: thin;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-ThinItalic.ttf") format("truetype");
  font-weight: thinItalic;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
      font-family: 'Roboto';
      src: url('Roboto-Regular-webfont.eot');
      src: url('Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
           url('Roboto-Regular-webfont.woff') format('woff'),
           url('Roboto-Regular-webfont.ttf') format('truetype'),
           url('Roboto-Regular-webfont.svg#RobotoRegular') format('svg');
      font-weight: normal;
      font-style: normal;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url('Roboto-Italic-webfont.eot');
      src: url('Roboto-Italic-webfont.eot?#iefix') format('embedded-opentype'),
           url('Roboto-Italic-webfont.woff') format('woff'),
           url('Roboto-Italic-webfont.ttf') format('truetype'),
           url('Roboto-Italic-webfont.svg#RobotoItalic') format('svg');
      font-weight: normal;
      font-style: italic;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url('Roboto-Bold-webfont.eot');
      src: url('Roboto-Bold-webfont.eot?#iefix') format('embedded-opentype'),
           url('Roboto-Bold-webfont.woff') format('woff'),
           url('Roboto-Bold-webfont.ttf') format('truetype'),
           url('Roboto-Bold-webfont.svg#RobotoBold') format('svg');
      font-weight: bold;
      font-style: normal;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url('Roboto-BoldItalic-webfont.eot');
      src: url('Roboto-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
           url('Roboto-BoldItalic-webfont.woff') format('woff'),
           url('Roboto-BoldItalic-webfont.ttf') format('truetype'),
           url('Roboto-BoldItalic-webfont.svg#RobotoBoldItalic') format('svg');
      font-weight: bold;
      font-style: italic;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url('Roboto-Thin-webfont.eot');
      src: url('Roboto-Thin-webfont.eot?#iefix') format('embedded-opentype'),
           url('Roboto-Thin-webfont.woff') format('woff'),
           url('Roboto-Thin-webfont.ttf') format('truetype'),
           url('Roboto-Thin-webfont.svg#RobotoThin') format('svg');
      font-weight: 200;
      font-style: normal;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url('Roboto-ThinItalic-webfont.eot');
      src: url('Roboto-ThinItalic-webfont.eot?#iefix') format('embedded-opentype'),
           url('Roboto-ThinItalic-webfont.woff') format('woff'),
           url('Roboto-ThinItalic-webfont.ttf') format('truetype'),
           url('Roboto-ThinItalic-webfont.svg#RobotoThinItalic') format('svg');  
      font-weight: 200;
      font-style: italic;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url('Roboto-Light-webfont.eot');
      src: url('Roboto-Light-webfont.eot?#iefix') format('embedded-opentype'),
           url('Roboto-Light-webfont.woff') format('woff'),
           url('Roboto-Light-webfont.ttf') format('truetype'),
           url('Roboto-Light-webfont.svg#RobotoLight') format('svg');
      font-weight: 100;
      font-style: normal;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url('Roboto-LightItalic-webfont.eot');
      src: url('Roboto-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
           url('Roboto-LightItalic-webfont.woff') format('woff'),
           url('Roboto-LightItalic-webfont.ttf') format('truetype'),
           url('Roboto-LightItalic-webfont.svg#RobotoLightItalic') format('svg');
      font-weight: 100;
      font-style: italic;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url('Roboto-Medium-webfont.eot');
      src: url('Roboto-Medium-webfont.eot?#iefix') format('embedded-opentype'),
           url('Roboto-Medium-webfont.woff') format('woff'),
           url('Roboto-Medium-webfont.ttf') format('truetype'),
           url('Roboto-Medium-webfont.svg#RobotoMedium') format('svg');
      font-weight: 300;
      font-style: normal;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url('Roboto-MediumItalic-webfont.eot');
      src: url('Roboto-MediumItalic-webfont.eot?#iefix') format('embedded-opentype'),
           url('Roboto-MediumItalic-webfont.woff') format('woff'),
           url('Roboto-MediumItalic-webfont.ttf') format('truetype'),
           url('Roboto-MediumItalic-webfont.svg#RobotoMediumItalic') format('svg');
      font-weight: 300;
      font-style: italic;
  } */

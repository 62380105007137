@media print {
  /* ... (other print styles) */
  body {
    font-family: "Times New Roman", serif;
  }
  .qr-code {
    position: absolute;
    top: 20px;
    left: 20px;
    font-family: "Times New Roman", serif;
  }
  .certificate-details-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    text-align: center; /* Center text within flex container */
    height: 100vh;
    font-family: "Times New Roman", serif;
  }

  .certificate-details-table {
    width: 100%;
    margin: 0 auto;
    text-align: left;
    width: 80%;
    font-family: "Times New Roman", serif;
  }

  .certificate-details-table th,
  .certificate-details-table td {
    padding: 5px 10px;
    font-family: "Times New Roman", serif;
  }

  .certificate-details {
    display: flex;
    align-items: center;
    font-family: "Times New Roman", serif;
  }

  .certificate-details {
    font-size: 16px;
    margin-right: 10px;
    font-family: "Times New Roman", serif;
  }

  .print-certificate {
    width: 297mm;
    height: 210mm;
    padding: 20mm;
    margin: 0 auto;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    font-family: "Times New Roman", serif;
  }

  .print-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #0969da;
    color: #fff;
    border: none;
    cursor: pointer;
  }

  .certificate-content {
    width: 100%; /* Adjust width as needed */
    height: 100%; /* Adjust height as needed */
    padding-bottom: 4mm;
    padding-top: 20mm;
    margin: 0 auto;
    outline: 1px double #ccc; /* Add a double border effect using outline */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    font-family: "Times New Roman", serif;
    border: 2px solid #000;
  }

  .outer-border {
    border: 2px solid #000; /* Adjust border style as needed */
    padding: 4px; /* Adjust padding as needed */
  }

  .table-header {
    width: 100%;
    table-layout: fixed;
    text-align: center;
  }

  .mini-title-header {
    text-align: left;
    padding-left: 15%;
    padding-top: 0px;
    font-family: "Times New Roman", serif;
    font-size: 10px;
    font-weight: bold;
  }
  .certificate-title {
    font-size: 30px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    left: 5px;
    margin-left: 5%;
    font-family: "Times New Roman", serif;
  }
  .second-title {
    font-size: 25px;
    font-weight: bold;
    font-family: "Times New Roman", serif;
    text-align: "center";
  }

  .title-programme {
    font-size: 30px;
    font-weight: bold;
    font-family: "Times New Roman", serif;
  }

  .specialization {
    font-size: 20px;
    font-weight: bold;
    font-family: "Times New Roman", serif;
  }

  .universityName {
    font-size: 20px;
    font-weight: bold;
    font-family: "Times New Roman", serif;
  }

  .small-title {
    font-size: 20px;
    font-family: "Times New Roman", serif;
  }

  .footer-signature {
    font-size: 17px;
    font-weight: bold;
    font-family: "Times New Roman", serif;
    margin-bottom: 0px;
  }
  .certificate-text {
    font-size: 18px;
    margin-bottom: 15px;
    font-family: "Times New Roman", serif;
  }

  .certificate-details {
    font-size: 16px;
    margin-bottom: 10px;
    font-family: "Times New Roman", serif;
  }

  .signature-table {
    width: 100%;
    table-layout: fixed; /* Prevent cells from adjusting width based on content */
  }

  .signature-cell {
    padding: 0 10px; /* Adjust padding as needed */
    text-align: left; /* Push content to the left */
  }

  .signature-box {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align content */
    margin-bottom: 14px;
  }

  .signature-image-container {
    flex: 0 0 auto; /* Ensure the image container doesn't grow */
  }

  .signature-img {
    width: 110px; /* Adjust image size as needed */
    height: 110px;
  }

  .signature-content {
    flex: 1; /* Let the content take remaining space */
    text-align: left; /* Push content to the left */

    font-size: 17px;
    font-weight: bold;
    font-family: "Times New Roman", serif;
  }

  .small-content {
    font-size: 16px;
    text-align: center;
  }

  /* logo */
  .logo-table {
    width: 100%;
    table-layout: fixed; /* Prevent cells from adjusting width based on content */
  }

  .logo-cell {
    padding: 0 0px; /* Adjust padding as needed */
    text-align: left; /* Push content to the left */
  }

  .logo-box {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align content */
    margin-bottom: 0px;
  }

  .logo-image-container {
    flex: 0 0 auto; /* Ensure the image container doesn't grow */
  }

  .logo-img {
    width: 70px; /* Adjust image size as needed */
    height: 70px;
  }

  .logo-content {
    flex: 1; /* Let the content take remaining space */
    text-align: left; /* Push content to the left */
  }
}

